.newsGrid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 2em;
	&__link {
		color: white;
		padding: $padding-sml $padding-lg;
		background-color: $color-purple-dark;
		text-decoration: none;
		& > h1 {
			margin-top: -0.5em;
			line-height: 1.3;
			padding-right: $padding-lg;
		}
		& > p {
			line-height: 1.8;
		}
	}
}
