.cardContainer {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 2em;
	grid-auto-flow: dense;
	// grid-auto-rows: 1fr;
	// grid-auto-rows: min-content;
}
.cardContainer__subgrid-1-row,
.cardContainer__subgrid-2-col {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 2em;
}

.catalogCard {
	border: 1px solid $color-black;
	background-color:#121226a6;
	border-radius: 2px;
	padding: 0 $padding-lg;
	transition: all 0.2s ease-in-out;
	z-index: 100;
	text-decoration: none;
	color: $color-black;
	// height: fit-content;

	& > h3 {
		color: $color-black;
	}
}

.catalogCard:hover {
	// box-shadow: -10px 10px 0px -2px $color-black;
	// transform: translate(5px, -1px);

	box-shadow: -3px 6px 5px 0px rgba(176, 164, 176, 1);
	transition: all .3s ease-in-out;

	box-shadow: rgba(190, 15, 179, 0.22) 0px 19px 43px;
	transform: translate3d(0px, -1px, 0px);
	transform: scale(1.05);
}

.exploreCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: $padding-sml $padding-lg;
	text-transform: capitalize;
}

.career__path {
	display: flex;
	flex-direction: column;

	&--svg {
		width: fit-content;
		align-self: center;
		padding-top: 3em;
		padding-bottom: 2.5em;
	}
	& > p {
		text-transform: capitalize;
		font-size: 0.8rem;
	}

	&-difficulty {
		background-color: $color-black;
		color: black;
		text-transform: uppercase;
		margin-right: 0.3em;
		// font-size: 0.9rem;
	}

	& > h3 {
		margin-top: -0.5em;
		text-transform: capitalize;
	}

	&--job {
		font-size: $font-size-sml;
		margin-top: auto;
		background-color: #fff5ff;
		padding: $padding-sml $padding-md;
		margin-left: auto;
		font-weight: 600;
	}
}

.course__level {
	display: flex;
	margin-top: -1.3em;
	align-items: baseline;
	& > li {
		list-style: none;
	}

	&--dot {
		height: 8px;
		width: 8px;
		border-radius: 50%;
		border: 1px solid $color-black;
	}

	& > * + * {
		margin-left: $margin-sml;
	}

	&--difficulty {
		color: #4b4b4d;
		font-size: 0.8rem;
	}
}

.dot-fill {
	background-color: $color-black;
}

.courseCard {
	display: flex;
	flex-direction: column;

	background-color: $color-yellow;
	& > h3 {
		margin-top: -0.5em;
		text-transform: capitalize;
	}
	&--progress {
		font-family: serif;
		display: flex;
		justify-content: space-between;
		margin-top: auto;
		align-items: baseline;
		text-transform: capitalize;
	}
	&--motivate-1 {
		font-size: $font-size-sml;
		text-transform: capitalize;
		font-family: Tahoma, Geneva, Verdana, sans-serif, serif;
		letter-spacing: 0.1rem;
	}
	&--motivate-2 {
		text-transform: capitalize;
		margin-left: auto;
		color: hsl(252, 87%, 38%);
	}
}

.programmingLang {
	display: flex;
	flex-direction: column;

	& > p {
		text-transform: capitalize;
		font-size: 0.8rem;
	}

	&-difficulty {
		background-color: $color-black;
		color: black;
		text-transform: uppercase;
		margin-right: 0.3em;
	}

	& > h3 {
		margin-top: -0.5em;
		text-transform: capitalize;
	}

	&--job {
		padding: $padding-sml $padding-md;

		background-color: #000;
		font-size: $font-size-sml;
		margin-top: auto;
		margin-left: auto;
		font-weight: 600;
	}
}
