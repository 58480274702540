.subjects {
    margin-left: 80px;
    margin-bottom: 50px;
    margin-right:150px;
    margin-top:100px;
}

.subjects h1 {
    color : red;
    text-align: center;
    margin-top:20px;
}

.subjects h2 {
    text-decoration:overline underline;
    margin-top:10px;
}


.subjects > h3 {
    margin-top:5px;
}

.list-title {
    font-weight: bold;
    display: block;
}