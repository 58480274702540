$color-black: #000;
$color-yellow: #FFE5CA;
$color-purple-dark: #fff;
$color-purple: hsl(252, 87%, 48%);
$color-bg-cream:#ffffff;
$font-size-sml: 0.875rem;

$margin-sml: 0.25em;
$margin-md: 0.5em;
$margin-lg: 1em;
$margin-xlg: 1.5em;

$padding-sml: 0.25em;
$padding-md: 0.5em;
$padding-lg: 1em;
$padding-xlg: 1.5em;

:root {
	box-sizing: border-box;
}

*,
::before,
::after {
	box-sizing: inherit;
}

body {
	background-color: $color-bg-cream;
	margin: 10px;
	font-family: 'Roboto', sans-serif;
	line-height: 1.6;
	font-weight:300
}

.btn {
	cursor: pointer;
	text-transform: capitalize;
	padding: 0.8em $padding-xlg;
	border-radius: 3px;
	outline: none;
	background-color: hsl(252, 87%, 48%);
	color: white;
	border: 0;
	font-size: 1rem;
}

.btn:hover {
	background-color: hsl(252, 87%, 55%);
}

pre {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
	font-size: 120%;
}

.codepen {
	height: 300px; 
	box-sizing: border-box; 
	display: flex; 
	align-items: center; 
	justify-content: center; 
	border: 2px solid; 
	margin: 1em 0; 
	padding: 1em;
}
